const media = `
url
width
height
mimeType
title
`;

const sections = `
__typename
...on entryBlock_Entry {
	title
	titleEnglish
	entryLink {
		title
		url
		uri
	}
	theme
	media {
		${media}
	}
	imageLayout
	text
	textEnglish
}
...on gallery_Entry {
	images {
		${media}
	}
}
...on text_Entry {
	headline
	headlineEnglish
	text
	textEnglish
}
...on contacts_Entry {
	title
	titleEnglish
	contactCardLayout
	contacts {
		...on contact_Entry {
			contactName
			contactTitle
			email
			image: image {
				${media}
			}
		}
	}
}
...on featuredEvents_Entry {
	title
	titleEnglish
	events {
		...on event_Entry {
			title
			slug
			media {
				${media}
			}
			category {
				title
				slug
			}
			dateFrom
			dateTo
			stage {
				title
			}
		}
	}
	entryLink {
		url
		title
		uri
	}
	linkText
	linkTextEnglish
}
...on videoEmbed_Entry {
	embedCode
}
...on dataTable_Entry {
	ticketLink
	data {
		title
		titleEnglish
		text
		textEnglish
		link
		linkTitle
		linkTitleEnglish
	}
}
...on featuredNews_Entry {
	news {
		...on post_Entry {
			title
			slug
			postDate
			media {
				${media}
			}
			excerpt
		}
	}
}
...on partnersBlock_Entry {
	partners {
		...on partners_Entry {
			title
			titleEnglish
			partnersList {
				name
				link
			}
		}
	}
}
...on locations_Entry {
	title
	titleEnglish
	headline
	textClean
	locations {
		...on location_Entry {
			title
			locationName
			linkUrl
			linkText
			linkTextEnglish
		}
	}
}
...on image_Entry {
	media {
		${media}
	}
}
...on foldOuts_Entry {
	foldOuts {
		...on foldOut_Entry {
			title
			titleEnglish
			text
			textEnglish
		}
	}
}
`;


export const homeQuery = () => {
	return `{
		homeEntries {
			...on home_Entry {
				title
				imageLayout
				promotedEvent {
					...on event_Entry {
						title
						slug
						media {
							${media}
						}
						dateFrom
						dateTo
						stage {
							title
						}
					}
				}
				sections {
					${sections}
				}
			}
		}
	}`;
}

export const pageQuery = (slug) => {
	return `{
		pagesEntries(slug: "${slug}") {
			...on pages_Entry {
				title
				titleEnglish
				media {
					${media}
				}
				heroLayout
				imageLayout
				sections {
					${sections}
				}
			}
		}
	}`;
}

export const eventQuery = (slug) => {
	return `{
		eventsEntries(slug: "${slug}") {
			...on event_Entry {
				title
				dateFrom
				dateTo
				ticketLink
				media {
					${media}
				}
				category {
					title
					slug
				}
				stage {
					title
					slug
				}
				sections {
					${sections}
				}
			}
		}
	}`;
}

export const eventsListQuery = (categories) => {
	return `{
		eventsEntries(
			relatedToCategories: {
				slug: [${categories.map(category => `"${category.slug}"`).join(",")}]
			},
			orderBy: "dateFrom",
      		dateTo: ">= ${new Date().toISOString().split('T')[0]} 00:00:00"
		){
			...on event_Entry {
				title
				slug
				media {
					${media}
				}
				stage {
					title
				}
				dateFrom
				dateTo
				category {
					title
					slug
				}
			}
		}
	}`;
}

export const eventsQuery = (slug) => {
	return `{
		calendarPagesEntries(slug: "${slug}") {
			...on calendarPages_Entry {
				title
				titleEnglish
				grid
				theme
				text
				textEnglish
				filtering
				category {
					title
					slug
				}
				imageLayout
				promotedEvent {
					...on event_Entry {
						title
						slug
						media {
							${media}
						}
						dateFrom
						dateTo
						stage {
							title
						}
					}
				}
			}
		}
	}`;
}

export const postQuery = (slug) => {
	return `{
		newsEntries(slug: "${slug}") {
			...on post_Entry {
				title
				authorName
				excerpt
				postContent
				postDate
				media {
					${media}
				}
				sections {
					${sections}
				}
			}
		}
	}`;
}

export const postListQuery = () => {
	return `{
		newsEntries(postDate: "<= now") {
			...on post_Entry {
				title
				slug
				postDate
				media {
					${media}
				}
				excerpt
			}
		}
	}`;
}

export const archiveQuery = () => {
	return `{
		events: entries(
			section: ["events"],
			dateFrom: "<= now",
		) {
			__typename
			...on event_Entry {
				title
				slug
				dateFrom
				dateTo
				sortDate: dateFrom
				text
				textEnglish
				media {
					${media}
				}
				category {
					title
					slug
				}
				stage {
					title
					slug
				}
			}
		}
		news: entries(
			section: ["news"],
			postDate: "<= now",
		) {
			__typename
			...on post_Entry {
				title
				slug
				postDate
				sortDate: postDate
				authorName
				excerpt
				media {
					${media}
				}
			}
		}
		stages: categories(group: "stage") {
			...on stage_Category {
				title
				slug
			}
		}
		categories: categories(group: "eventCategory") {
			...on eventCategory_Category {
				title
				slug
			}
		}
	}`;
}

export const gloablQuery = () => {
	return `{
		globalSets {
			... on navigation_GlobalSet {
			mainMenu {
				... on menuItem_Entry {
				title
				titleEnglish
				entryLink {
					uri
				}
				}
			}
			secondaryMenu {
				... on menuItem_Entry {
				title
				titleEnglish
				entryLink {
					uri
				}
				}
			}
			}
			... on newsletter_GlobalSet {
				contactTitle
				titleEnglish
				textClean
			}
			... on footer_GlobalSet {
			quickLinks1 {
				... on quickLink_Entry {
				title
				entryLink {
					uri
				}
				}
			}
			footerLinks1 {
				... on footerLinks_Entry {
				title
				footerLink {
					... on footerLinkItem_Entry {
					title
					entryLink {
						uri
					}
					externalLink
					}
				}
				}
			}
			footerLinks2 {
				... on footerLinks_Entry {
				title
				footerLink {
					... on footerLinkItem_Entry {
					title
					entryLink {
						uri
					}
					externalLink
					}
				}
				}
			}
			footerLinks3 {
				... on footerLinks_Entry {
				title
				footerLink {
					... on footerLinkItem_Entry {
					title
					entryLink {
						uri
					}
					externalLink
					}
				}
				}
			}
			socialLinks {
				... on socialLink_Entry {
				title
				externalLink
				}
			}
			}
		}
	}`;
	// return `{
	// 	globalSets {
	// 		...on navigation_GlobalSet {
	// 			mainMenu {
	// 				...on menuItem_Entry {
	// 					title
	// 					titleEnglish
	// 					entryLink {
	// 						uri
	// 					}
	// 				}
	// 			}
	// 			secondaryMenu {
	// 				...on menuItem_Entry {
	// 					title
	// 					titleEnglish
	// 					entryLink {
	// 						uri
	// 					}
	// 				}
	// 			}
	// 		}
	// 		...on newsletter_GlobalSet {
	// 			newsletterTitle: contactTitle
	// 			newsletterTitleEnglish: titleEnglish
	// 			newsletterText: textClean
	// 		}
	// 		...on footer_GlobalSet {
	// 			quickLinks1 {
	// 				...on quickLink_Entry {
	// 					title
	// 					entryLink {
	// 						uri
	// 					}
	// 				}
	// 			}
	// 			footerLinks1 {
	// 				...on footerLinks_Entry {
	// 					title
	// 					footerLink {
	// 						...on footerLinkItem_Entry {
	// 							title
	// 							entryLink {
	// 								uri
	// 							}
	// 							externalLink
	// 						}
	// 					}
	// 				}
	// 			}
	// 			footerLinks2 {
	// 				...on footerLinks_Entry {
	// 					title
	// 					footerLink {
	// 						...on footerLinkItem_Entry {
	// 							title
	// 							entryLink {
	// 								uri
	// 							}
	// 							externalLink
	// 						}
	// 					}
	// 				}
	// 			}
	// 			footerLinks3 {
	// 				...on footerLinks_Entry {
	// 					title
	// 					footerLink {
	// 						...on footerLinkItem_Entry {
	// 							title
	// 							entryLink {
	// 								uri
	// 							}
	// 							externalLink
	// 						}
	// 					}
	// 				}
	// 			}
	// 			socialLinks {
	// 				...on socialLink_Entry {
	// 					title
	// 					externalLink
	// 				}
	// 			}
	// 		}
	// 	}
	// }`;
}