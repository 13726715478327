import { default as _91_46_46_46slug_93ec3DtUqyCyMeta } from "/opt/build/repo/frontend/pages/[...slug].vue?macro=true";
import { default as archiveGec4lSvPvlMeta } from "/opt/build/repo/frontend/pages/archive.vue?macro=true";
import { default as _91_46_46_46slug_93zs5rzmD0L8Meta } from "/opt/build/repo/frontend/pages/event/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_938Kgl7dRhWgMeta } from "/opt/build/repo/frontend/pages/events/[...slug].vue?macro=true";
import { default as indexdATqU9DngJMeta } from "/opt/build/repo/frontend/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_936VKQwyGKvtMeta } from "/opt/build/repo/frontend/pages/news/[...slug].vue?macro=true";
import { default as indexoWNztjgVJGMeta } from "/opt/build/repo/frontend/pages/news/index.vue?macro=true";
import { default as styleguidet6cfQSN7f5Meta } from "/opt/build/repo/frontend/pages/styleguide.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/opt/build/repo/frontend/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "archive",
    path: "/archive",
    component: () => import("/opt/build/repo/frontend/pages/archive.vue").then(m => m.default || m)
  },
  {
    name: "event-slug",
    path: "/event/:slug(.*)*",
    component: () => import("/opt/build/repo/frontend/pages/event/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "events-slug",
    path: "/events/:slug(.*)*",
    component: () => import("/opt/build/repo/frontend/pages/events/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/build/repo/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "news-slug",
    path: "/news/:slug(.*)*",
    component: () => import("/opt/build/repo/frontend/pages/news/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/opt/build/repo/frontend/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: "styleguide",
    path: "/styleguide",
    component: () => import("/opt/build/repo/frontend/pages/styleguide.vue").then(m => m.default || m)
  }
]